<template>
	<v-flex>
		<v-dialog :value="value" @input="$emit('input')" fullscreen hide-overlay transition="dialog-bottom-transition">


			<v-card>
				<v-card-title>
					<span class="headline" v-if="!viewCustom">Ajouter un projet</span>
					<span class="headline" v-if="viewCustom">Modifier le projet {{ projectEntry.name }}</span>
				</v-card-title>

				<v-container>
					<v-form ref="form" class="form">

						<v-row>
							<v-col cols="12" sm="9">
								<v-text-field label="Intitulé" hint="Nom du Projet" v-model="projectEntry.title" :rules="this.$fieldsRules.required('nom')"></v-text-field>
							</v-col>
							<v-col cols="12" sm="3">
								<v-text-field label="Valeur" type="number" suffix="€" hint="Valeur du projet" v-model="projectEntry.value"></v-text-field>
							</v-col>
						</v-row>

						<v-row>
							<v-col cols="12" sm="4">
								<v-autocomplete clearable v-model="projectEntry.customers" :items="people" chips deletable-chips color="blue-grey lighten-2" label="Client" item-text="id" item-value="id" multiple>
									<template v-slot:selection="data">
										<v-chip v-bind="data.attrs" :input-value="data.selected" @click="data.select" close @click:close="remove(data.item)">
											<v-avatar left>
												<v-img contain :src="$functions.getImgLink(data.item.avatar, 50)"></v-img>
											</v-avatar>
											{{ data.item.name }}
										</v-chip>
									</template>
									<template v-slot:item="data">
										<template v-if="data.header">
											<v-list-item-content v-text="data.item"></v-list-item-content>
										</template>
										<template v-else>
											<v-list-item-avatar>
												<v-img contain :src="$functions.getImgLink(data.item.avatar, 50)"></v-img>
											</v-list-item-avatar>
											<v-list-item-content>
												<!-- v-html="data.item.name" -->
												<v-list-item-title>{{ data.item.name }}</v-list-item-title>
												<v-list-item-subtitle v-html="'Numéro ' + data.item.denom + ' : ' + data.item.content"></v-list-item-subtitle>
											</v-list-item-content>
										</template>
									</template>
								</v-autocomplete>

								<v-select v-model="projectEntry.type" :items="this.$listUtils.projectCategory" label="Type" required></v-select>
								
								<ImageUpload v-if="userInfo.company" :companyID="userInfo.company" type="project" label="Logo du projet" @newdata="projectEntry.avatar=$event"></ImageUpload>
								<v-img :src="$functions.getImgLink(projectEntry.avatar)" contain></v-img>
							</v-col>
							<v-col cols="12" sm="8">
								<v-textarea v-model="projectEntry.content" name="input-1-1" label="Description" hint="Description précise du projet"></v-textarea>

								<h4 class="text--lighten-2 font-weight-light">Date de rendu</h4>
								<v-date-picker first-day-of-week="1" v-model="projectEntry.due" reactive landscape center width="40vw"></v-date-picker>
							</v-col>
						</v-row>

					</v-form>
				</v-container>

				<v-btn id="lateral" fab small outlined color="primary" @click.native="$emit('input', false)">
					<v-icon>mdi-chevron-down</v-icon>
				</v-btn>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn class="ma-2 mr-10" outlined color="success" v-if="!viewCustom" @click="submit">Ajouter</v-btn>
					<v-btn class="ma-2 mr-10" outlined color="success" v-if="viewCustom" @click="submit">Modifier</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-flex>

</template>


<script>
	import ImageUpload from '@/components/graphics/ImageUpload';

	export default {
		props: {
			'value': {},
			'id': {
				type: String
			}
		},
		components: {
			ImageUpload
		},
		data() {
			return {
				userInfo: this.$models.user,

				pro: false,
				dialogDeleteItem: false,

				identifiant: null,

				date: null,
				menu: false,
				picker: new Date().toISOString().substr(0, 10),

				dialog: false,

				viewCustom: false,

				projectEntry: this.$models.project,

				people: [],
				selectCustom: []
			}
		},

		watch: {
			menu(val) {
				val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
			},

			id: function () {
				this.preModifyCustomer(this.id);
			},
		},

		methods: {
			remove(item) {
				const index = this.projectEntry.customers.indexOf(item.id);
				if (index >= 0) this.projectEntry.customers.splice(index, 1);
			},

			resetFields() {
				if (this.viewCustom) {
					this.viewCustom = false,

					this.company = '',

					this.projectEntry = this.$models.project
				}
			},

			submit() {
				if(!this.$refs.form.validate()) return;

				const project = {
					...this.projectEntry,
					'creationDate': new Date(),

					'status': 1
				};

				if(this.projectEntry.due) project.due = new Date(this.projectEntry.due);

				if (!this.viewCustom) {
					this.$db.collection("companies").doc(this.userInfo.company).collection("projects").add(project).then(() => {
						this.$refs.form.resetValidation();

						this.$store.dispatch('setSnackbar', {
							text: `Le projet ${project.title} a été ajouté !`,
						});

						this.resetFields();
					});
				} else {
					this.$db.collection("companies").doc(this.userInfo.company).collection("projects").doc(this.modifiedCustom).set(project, {
						merge: true
					});

					this.$store.dispatch('setSnackbar', {
						text: `Le projet ${project.title} a été modifié !`,
					});
				}

				this.$emit('input', false);

				this.viewCustom = true;
				this.resetFields();
			},

			preModifyCustomer(id) {
				this.$db.collection("companies").doc(this.userInfo.company).doc(id).onSnapshot(doc => {
					this.projectEntry = doc.data();

					if (this.projectEntry.company) {
						this.$db.collection("companies").doc(this.projectEntry.company).onSnapshot(doc => {
							this.companyEntry = doc.data();
						});
					}
				});

				this.modifiedCustom = id;

				this.viewCustom = true;

				this.dialog = true;
			},

			async viewCustomer(id) {
				this.$router.replace({
					path: "record/customer/" + id
				});
			}
		},

		created() {
			this.resetFields();

			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.userInfo = doc.data();
			});

			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.people = [
					{ header: 'Clients' }
				]

				this.$db.collection("companies").doc(this.userInfo.company).collection("customers").onSnapshot((res) => {
					res.forEach((document) => {
						this.people.push({
							'name': document.data().lastName.toUpperCase() + " " + document.data().firstName,
							'content': document.data().nClient,
							'id': document.id,
							'avatar': document.data().avatar,
							'denom': "client"
						});
					});

					this.people.push(
						{ divider: true },
						{ header: 'Entreprises' }
					)

					this.$db.collection("companies").doc(doc.data().company).collection("companies").onSnapshot((res) => {
						res.forEach((document) => {
							this.people.push({
								'name': document.data().name,
								'content': document.data().nCompany,
								'id': document.id,
								'avatar': document.data().avatar,
								'denom': "entreprise"
							});
						});
					});
				});
			});
		}
	};
</script>
<template>
	<div class="projects">

		<Head :title="Head.Title" :baliseTitle="Head.baliseTitle" :titleWeight="Head.TitleWeight"
			:subTitle="Head.SubTitle" :subTitle2="Head.SubTitle2" :bgUrl="Head.bgUrl" :bgUrlLazy="Head.bgUrlLazy"
			:offset="Head.offset" :height="Head.height" :overlayOpacity="Head.overlayOpacity"
			:overlayColor="Head.overlayColor" :imgBlur="Head.imgBlur" :transitionRotate="Head.transitionRotate"
			:titleColor="Head.TitleColor" />

		<v-container>
			<v-card class="glassmorphism pa-4 pt-0 mx-auto" max-width="1280" color="transparent">
				<ButtonTrigger @click.native="dialog = !dialog" title="Projets" content="Ajouter un Projet" whiteText />
				<Projects :projects="projects" class="maxWidth mx-auto" />
			</v-card>
		</v-container>
		<ProjectDialog v-model="dialog" :compArray="this.projects" />
	</div>
</template>

<script>
	import Head from '@/components/graphics/head'
	import ButtonTrigger from '@/components/graphics/buttonTrigger'
	import Projects from '@/components/modules/@project/ProjectsTable';
	import ProjectDialog from '@/components/modules/@project/ProjectDialog';

	export default {
		head: {
			title: {
				inner: "Projets"
			}
		},
		components: {
			Head,
			ButtonTrigger,
			Projects,
			ProjectDialog
		},
		data() {
			return {
				dialog: false,
				projects: [],
				Head: {

					Title: 'Projets',
					baliseTitle: 'h2', // h1, h2, h3, h4, h5, h6
					TitleWeight: 'thin', // thin / light / regular / medium / bold / black
					TitleColor: 'white', // white / black / primary

					SubTitle: '',
					SubTitle2: "",

					bgUrl: "https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg.jpg",
					bgUrlLazy: "https://hostcrush.cloud/echange/VueDesignProps/Backgrounds/ktna-bg-lazy.jpg",

					offset: "10vh",
					height: "65vh",

					overlayOpacity: "0.3",
					overlayColor: "black",
					imgBlur: "0px",

					transitionRotate: "180"

				},
			};
		},

		created() {
			this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
				this.userInfo = doc.data();
				this.projects = [];

				function pushToCustomer(r, tasks, document, customerData = null) {
					var returnData = {
						...document.data(),
						id: document.id
					};

					if (customerData) returnData.participantsData = customerData;

					if (r == -1) tasks.push(returnData);

					return tasks;
				}

				this.$db.collection("companies").doc(doc.data().company).collection("projects").onSnapshot((
					res) => {
					this.projects = [];

					res.forEach((document) => {
						if (document.data().customers) {
							var participantsData = [];

							document.data().customers.forEach((item) => {
								this.$db.collection("companies").doc(this.userInfo.company)
									.collection("companies").doc(item).get().then((
										doc) => {
										if (doc.data()) {
											participantsData.push({
												...doc.data(),
												id: doc.id
											});
										} else {
											this.$db.collection("companies").doc(this
												.userInfo.company).collection(
												"companies").doc(item).get().then((
												doc) => {
												participantsData.push({
													...doc.data(),
													id: doc.id
												});
											});
										}
									});

								this.projects = pushToCustomer(this.$functions
									.findWithAttr(this.projects, "id", document.id),
									this.projects, document, participantsData);
							})
						} else {
							this.projects = pushToCustomer(this.$functions.findWithAttr(this
								.projects, "id", document.id), this.projects, document);
						}
					});
				});

				/*this.$db.collection("companies").doc(doc.data().company).collection("projects").onSnapshot((res) => {
					this.projects = [];

					res.forEach((document) => {
						console.log(document.data())

						document.data().customer.forEach((customer) => {
							this.$db.collection("companies").doc(doc.data().company).collection("customers").doc(customer).onSnapshot(docCus => {

								if(!docCus.data()) {
									this.$db.collection("companies").doc(doc.data().company).collection("companies").doc(customer).onSnapshot(docComp => {
										this.projects = pushInProjects(this.projects, document, {
											...docComp.data(),
											id: docComp.id
										});
									});
								}
								else {
									this.projects = pushInProjects(this.projects, document, {
										...docCus.data(),
										id: docCus.id
									});
								}

								this.customersProject[customer].avatar = "https://d1fmx1rbmqrxrr.cloudfront.net/cnet/optim/i/edit/2019/04/eso1644bsmall__w770.jpg"
							});
						});

					});
				});*/

			});
		}
	}

</script>

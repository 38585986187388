<template>
	<v-container class="my-5">

		<DataTable title="Projects" type="projects" :headers="$tableHeaders.projects" :items="projects"
			:itemKey="projectEntry.id" link="/record/project" />

	</v-container>
</template>


<script>
	import DataTable from '@/components/modules/#global/DataTable';

	export default {
		props: {
			'id': {
				type: String
			},
			'projects': {
				type: Array
			}
		},
		components: {
			DataTable,
		},
		data() {
			return {
				expanded: [],
				search: '',
				customersProject: {},

				projectEntry: this.$models.project,

				dialogs: {
					delete: false
				},

				deletedProject: this.$models.project
			}
		},
		methods: {
			deleteProject() {
				this.$db.collection("users").doc(this.$firebase.auth().currentUser.uid).onSnapshot(doc => {
					this.$katana.removeAllDataFromDocument(this.$db.collection("companies").doc(doc.data().company).collection("projects").doc(this.deletedProject.id), `Le projet ${this.deletedProject.title} a été supprimé !`);

					this.dialogs.delete = false;
				});
			}
		}
	}

</script>
